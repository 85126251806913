/** @format */

import React, { useState, useEffect, useRef } from "react"
import Form from "react-bootstrap/Form"
import Div from "./Div"
import { FiAlertCircle, FiCheck } from 'react-icons/fi'
import { AiOutlineClose } from 'react-icons/ai'
import LockedValue from './LockedValue'
import FormLabel from "./FormLabel"

const Input = ({ show, type, label, placeholder, initValue, onChange, onClear, onKeyDown, onClick, disabled, allowedKeysTest, checkValidity, valueFormatter, noLabel, noFeedback, lockValue, className, valid, invalid, final, triggerCheck, defaultInvalidText, required, wrap, scrollIntoView, ...props }) => {

    onChange = onChange || (() => {})
    onClear = onClear || (() => {})
    onKeyDown = onKeyDown || (() => {})
    onClick = onClick || (() => {})
    defaultInvalidText = defaultInvalidText || (label ? `Favor introduzca ${label}` : 'Inválido')

    const [value, setValue] = useState(initValue||"")
    const [isValid, setIsValid] = useState(valid ? true : undefined)
    const [isInvalid, setIsInvalid] = useState(invalid ? true : undefined)
    const [invalidText, setInvalidText] = useState(defaultInvalidText)
    const [validity, setValidity] = useState(valid ? "valid" : (invalid ? "invalid" : ""))
    const [isFinal, setIsFinal] = useState(final ? true : false)
    const [check, setCheck] = useState(false)

    const inputRef = useRef()

    const setInvalid = (val, msg, stopPropagation) => {
        setInvalidText(msg)
        setIsValid(false)
        setIsInvalid(true)
        setValidity("invalid")
        if (!stopPropagation) onChange({value: val, isValid: false})
    }

    const setValid = (val, stopPropagation) => {
        setIsValid(true)
        setIsInvalid(false)
        setValidity("valid")
        if (!stopPropagation) onChange({value: val, isValid: true})
    }

    const checkValue = (val, stopPropagation) => {
        if (valueFormatter) val = valueFormatter(val)
        setValue(val)

        let check = {msg: invalidText}
        if (checkValidity) check = checkValidity(val)
        else if (valid || final) check.isValid = true
        else check.isValid = false

        if (check.isValid) setValid(val, stopPropagation)
        else setInvalid(val, check.msg, stopPropagation)
    }

    const handleChange = e => {
        let currentValue = e.target.value
        setCheck(true)
        // console.log(currentValue)
        if (currentValue === undefined || currentValue === "") {
            setValue("")
            setInvalid("", defaultInvalidText)
        } else {
            checkValue(currentValue)
        }
    }

    const handleKeyDown = e => {
        let allowedKeys = [
            "Backspace",
            "Delete",
            "Enter",
            "Tab",
            "ArrowLeft",
            "ArrowRight",
        ]
        let isAllowed = true
        if (allowedKeysTest) {
            isAllowed = allowedKeysTest(e.key)
        }
        if (!isAllowed && !allowedKeys.includes(e.key)) e.preventDefault()
        onKeyDown(e)
    }

    const handleOnClick = e => {
        if (scrollIntoView) e.target.scrollIntoView()
        onClick(e)
    }

    const handleBlur = () => {
        // console.log({isValid, lockValue, value})
        if (isValid && lockValue && value.length > 0) {
            setIsFinal(true)
        }
    }
    
    const clear = (invalidValue) => {
        setValue('')
        setIsValid(undefined)
        setIsInvalid(invalidValue)
        setInvalidText(defaultInvalidText)
        setValidity(invalidValue ? "invalid" : "")
        setIsFinal(false)
        onChange({value: "", isValid: false})
    }

    const handleEditFinal = e => {
        clear(true)
        // setIsInvalid(true)
        onClear()
    }

    useEffect(() => {
        if (initValue === undefined) clear()
        // else if (initValue === '') clear(true)
        else setValue(initValue)
    }, [initValue])

    // useEffect(() => {
    //     if (isValid) setValidity("valid")
    //     else if (isInvalid) setValidity("invalid")
    //     else setValidity('')
    // }, [isValid, isInvalid])

    useEffect(() => {
        if (valid !== undefined && valid) {
            setIsValid(true)
            setValidity("valid")
        }
        if (invalid !== undefined && invalid) {
            setIsInvalid(true)
            setValidity("invalid")
        }
    }, [valid, invalid])

    // useEffect(() => {
    //     if (!isFinal && inputRef.current && inputRef.current.focus) {
    //         inputRef.current.focus()
    //     }
    // }, [isFinal])

    useEffect(() => {
        setIsFinal(final ? true : false)
    }, [final])

    useEffect(() => {
        if (check) checkValue(value, true)
    },[checkValidity, check])

    useEffect(() => {
        if (triggerCheck) checkValue(value)
    }, [triggerCheck])

    if (!show) return <></>
    return (
        <div className="p-2">
            <FormLabel
                label={noLabel ? null : label}
                required={required}
            />
            <Div show style={{ position: "relative" }}>
                <input
                    ref={inputRef}
                    type={type}
                    className={`form-input ${validity}`}
                    value={value}
                    placeholder={placeholder || label}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onClick={handleOnClick}
                    disabled={disabled}
                    hidden={isFinal}
                    {...props}
                />
                <LockedValue
                    show={isFinal}
                    value={value}
                    onClick={handleEditFinal}
                    wrap={wrap}
                />
                <Div 
                    show={isValid && !isFinal}
                    className="form-valid form-floating-icon"
                >
                    <FiCheck/>
                </Div>
                <Div 
                    show={isInvalid && !isFinal} 
                    className="form-invalid form-floating-icon"
                >
                    <FiAlertCircle/>
                </Div>
                
            </Div>
            <Div show={isInvalid && !noFeedback && !isFinal} className="form-invalid">
                {invalidText}
            </Div>
        </div>
    )
}

export default Input
