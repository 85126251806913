/** @format */

import React, { useState, useMemo } from "react"
import Modal from "./Modal"
import Utils from "../utils"
import Datetime from "./Datetime"
import Discharge from "./Discharge"

const isDate = d => typeof d.getMonth === 'function'

const Datum = ({ label, val }) => {
    return (
        <div className="row m-0 px-0 pt-0 pb-2">
            <div className="col m-0 px-2 py-1 col-12 col-sm-4 col-md-3 bg-dark bg-opacity-25 rounded-start">
                {label}
            </div>
            <div className="col m-0 px-2 py-1 col-12 col-sm-8 col-md-9 bg-dark bg-opacity-10 rounded-end">
                {val}
            </div>
        </div>
    )
}

const PatientModal = ({ show, onDismiss, onSave, patient, formData, supabase }) => {

    const [masterKey, setMasterKey] = useState(1)
    const [triggerCheck, setTriggerCheck] = useState(0)
    const [dischargeDatetime, setDischargeDatetime] = useState({})
    const [discharge, setDischarge] = useState({})

    const triageEndLabel = "Finalización de Triaje"

    const triageEndDatetime = useMemo(() => {
        return new Date(patient.triage_end_ts)
    }, [patient])

    let age =
        patient.p_age_yr > 0
            ? `${patient.p_age_yr} años`
            : `${patient.p_age_mth} meses`

    const formatDate = date => {
        let dt = null
        if (typeof date === 'string') dt = new Date(date)
        else if (isDate(date)) dt = date
        else return "#err"

        let day = Utils.date2pretty(dt, "ES")
        let hours = dt.getHours() % 12
        if (hours === 0) hours = 12
        let tod = dt.getHours() > 12 ? "PM" : "AM"
        let minutes = dt.getMinutes()
        hours = hours.toString().padStart(2,"0")
        minutes = minutes.toString().padStart(2,"0")
        return `${day} ${hours}:${minutes} ${tod}`
    }

    const update = async ({ pid, ...payload }) => {
        let { data, error, ...resp } = await supabase
            .from("patient_log")
            .update(payload)
            .eq('pid', pid)
        if (error) throw error
        console.log(resp)
        return data
    }

    const handleSave = () => {
        setTriggerCheck(t => ++t)
        let cols = [discharge, dischargeDatetime]
        let isValid = cols.reduce((p,c) => c && c.isValid && p, true)
        if (isValid) {
            let data = {
                pid: patient.pid,
                discharge_ts: dischargeDatetime.date.toISOString(),
                disc_id: discharge.disc_id,
            }
            console.log(data)
            update(data).then(data => {
                if (onSave) onSave()
                setMasterKey(k => ++k)  // clean input => force re-render
            })
        }
    }

    const handleDismiss = () => {
        setMasterKey(k => ++k)
        if (onDismiss) onDismiss()
    }

    return (
        <Modal
            show={show}
            onDismiss={handleDismiss}
            onApply={handleSave}
            dismissLabel="Cancelar"
            applyLabel="Actualizar"
        >
            <Datum label="Nombre" val={patient.p_name} />
            <Datum label="Edad" val={age} />
            <Datum label="Medio de Llegada" val={patient.trans_ty} />
            <Datum label="Seguro Médico" val={patient.ins_na + (patient.ins_id > 90 ? ' / ' + patient.other_ins_na : '') } />
            <Datum label="Motivo de Consulta" val={patient.rsn_na + (patient.rsn_id > 90 ? ' / ' + patient.other_rsn_na : '')} />
            <Datum label="Ingreso a Sala" val={formatDate(patient.arrival_ts)} />
            <Datum label="Inicio de Triaje" val={formatDate(patient.triage_beg_ts)} />
            <Datum label={triageEndLabel} val={formatDate(patient.triage_end_ts)} />
            <Datum label="Clasificación de Riesgo" val={patient.risk_na} />
            <Datum label="Diagnóstico Primario" val={patient.diag_pri_na} />
            <Datum label="Diagnóstico Secundario" val={patient.diag_sec_na} />
            <Datum label="Destino en Sala de Emergencia" val={patient.dest_na} />
            <Datetime
                key={`modal_dt_discharge_${masterKey}`}
                label="Egreso"
                onSelection={setDischargeDatetime}
                date={triageEndDatetime}
                depLabel={triageEndLabel}
                minTimeDifference="24h"
                count={2}
                offset={1}
                required
                triggerCheck={triggerCheck}
            />
            <Discharge
                key={`modal_disc_${masterKey}`}
                data={formData.discharge}
                onSelection={setDischarge}
                triggerCheck={triggerCheck}
            />
        </Modal>
    )
}

export default PatientModal
