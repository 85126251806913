function RGBToHSL(r,g,b) {
    // source: https://css-tricks.com/converting-color-spaces-in-javascript/
    // Make r, g, and b fractions of 1
    r /= 255;
    g /= 255;
    b /= 255;
  
    // Find greatest and smallest channel values
    let cmin = Math.min(r,g,b),
        cmax = Math.max(r,g,b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    // Calculate hue
    // No difference
    if (delta === 0)
    h = 0;
    // Red is max
    else if (cmax === r)
    h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax === g)
    h = (b - r) / delta + 2;
    // Blue is max
    else
    h = (r - g) / delta + 4;

    h = Math.round(h * 60);
    
    // Make negative hues positive behind 360°
    if (h < 0) h += 360;

    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        
    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return [h, s, l]
}

function HSLToRGB(h,s,l) {
    // Must be fractions of 1
    s /= 100;
    l /= 100;
  
    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs((h / 60) % 2 - 1)),
        m = l - c/2,
        r = 0,
        g = 0,
        b = 0;
    
    if (0 <= h && h < 60) {
        r = c; g = x; b = 0;  
    } else if (60 <= h && h < 120) {
        r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
        r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
        r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
        r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
        r = c; g = 0; b = x;
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return [r, g, b]
}

var uint8hex  = u => u.reduce((p,c)=>p+c.toString(16).padStart(2,'0'),'')

/**
 * Convert a hex string to a byte array
 * @param {string} hex 
 * @returns {number[]} bytes
 */
function hexToBytes(hex) {
    if(hex.substr(0,2) === '0x') hex = hex.substr(2)
    if(hex.length % 2 === 1) hex = '0'+ hex
    for (var bytes = [], c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes
}

const xtob = hex => hexToBytes(hex)
const btox = u8 => uint8hex(u8)

const hexColorToRGB = x => xtob(x.replace('#',''))
const RGBToHexColor = (r,g,b) => `#${btox([r,g,b,])}`

const lighten = (hexColor, delta=10) => {
    let rgb = hexColorToRGB(hexColor)
    let [h,s,l] = RGBToHSL(...rgb)
    l = l + delta > 100 ? 100 : l + delta
    rgb = HSLToRGB(h, s, l)
    return RGBToHexColor(...rgb)
}

const darken = (hexColor, delta=10) => {
    let rgb = hexColorToRGB(hexColor)
    let [h,s,l] = RGBToHSL(...rgb)
    l = l - delta < 0 ? 0 : l - delta
    rgb = HSLToRGB(h, s, l)
    return RGBToHexColor(...rgb)
}

const Monokai = {
    mkBg0: '#272822',
    mkBg1: '#3e3d32',
    mkBg2: '#75715e',
    mkFg0: '#f8f8f2',
    mkFg1: '#cfcfc2',
    mkYellow: '#e6db74',
    mkOrange: '#fd971f',
    mkRed: '#f92672',
    mkMagenta: '#fd5ff0',
    mkViolet: '#ae81ff',
    mkBlue: '#66d9ef',
    mkCyan: '#a1efe4',
    mkGreen: '#a6e22e',
}

const Colors = {
    red: '#F05454',
    green: '#03C4A1',
    lightBlue: '#1597BB',
    darkTeal: '#064663',
    gold: '#ECB365',
    orange: '#D89216',
    dark: '#30475E',
    darker: '#222831',
    light: '#DDDDDD',
    ...Monokai,
    mkYellows: [lighten(Monokai.mkYellow), Monokai.mkYellow, darken(Monokai.mkYellow)],
    mkOranges: [lighten(Monokai.mkOrange), Monokai.mkOrange, darken(Monokai.mkOrange)],
    mkReds: [lighten(Monokai.mkRed), Monokai.mkRed, darken(Monokai.mkRed)],
    mkMagentas: [lighten(Monokai.mkMagenta), Monokai.mkMagenta, darken(Monokai.mkMagenta)],
    mkViolets: [lighten(Monokai.mkViolet), Monokai.mkViolet, darken(Monokai.mkViolet)],
    mkBlues: [lighten(Monokai.mkBlue), Monokai.mkBlue, darken(Monokai.mkBlue)],
    mkCyans: [lighten(Monokai.mkCyan), Monokai.mkCyan, darken(Monokai.mkCyan)],
    mkGreens: [lighten(Monokai.mkGreen), Monokai.mkGreen, darken(Monokai.mkGreen)],
}

// Object.entries(Colors).map(([k,v]) => {
//     console.log(`${k}\t${lighten(v)}\t${darken(v)}`)
// })

export default Colors