/** @format */

import React, { useState, useEffect } from "react"
import FormGroup from "./FormGroup"
import PrimaryDiagnostic from "./PrimaryDiagnostic"
import SecondaryDiagnostic from "./SecondaryDiagnostic"

const Diagnostic = ({ label, data, data2, onSelection, triggerCheck }) => {
    const [diagnostic, setDiagnostic] = useState({})
    const [primary, setPrimary] = useState({})
    const [secondary, setSecondary] = useState({})
    const [dataSecondary, setDataSecondary] = useState([])
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        let isValid = false
        if (!primary || !primary.diag_pri_cd) setDataSecondary([])
        else {
            let filteredData = data2.filter(x => x.diag_pri_cd === primary.diag_pri_cd)
            if (filteredData.length === 0) {
                setShowModal(false)
                isValid = true
            }
            setDataSecondary(filteredData)
        }
        if (!primary.isValid) setSecondary({})
        setDiagnostic(d => ({ ...d, isValid, primary }))
    }, [primary])

    useEffect(() => {
        let isValid = false
        if (secondary && secondary.diag_sec_cd) {
            setShowModal(false)
            isValid = true
        }
        setDiagnostic(d => ({ ...d, isValid, secondary }))
    }, [secondary])

    useEffect(() => {
        if (onSelection) onSelection(diagnostic)
    },[diagnostic])

    return (
        <div className={showModal ? "am-modal" : ""}>
            <div className={showModal ? "am-modal-container" : ""}>
                <FormGroup type="column" label={label} labelClassName="p-2" required>
                    <PrimaryDiagnostic
                        data={data}
                        onSelection={setPrimary}
                        onFocus={() => setShowModal(true)}
                        triggerCheck={triggerCheck}
                        noLabel
                        />
                    <SecondaryDiagnostic
                        hide={dataSecondary.length === 0}
                        data={dataSecondary}
                        onSelection={setSecondary}
                        onFocus={() => setShowModal(true)}
                        triggerCheck={triggerCheck}
                        noLabel
                    />
                </FormGroup>
            </div>
        </div>
    )
}

export default Diagnostic
