/** @format */

import React from "react"

import Div from "./Div"

const FormLabel = ({ label, className, required, ...props }) => {
    return (
        <Div
            show={label}
            className={className}
            {...props}
        >
            {label}
            { required && <span className="text-danger">{' *'}</span> }
        </Div>
    )
}

export default FormLabel
