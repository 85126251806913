/** @format */

import React, { useState, useEffect } from "react"
import Regions from "./Regions"
import Button from "react-bootstrap/Button"
import Hospitals2 from "./Hospitals2"
import PatientName from "./PatientName"
import PatientAge from "./PatientAge"
import Transportation from "./Transportation"
import Insurers from "./Insurers"
import Div from "./Div"
import FormLabel from "./FormLabel"
import Datetime from "./Datetime"
import Risks from "./Risks"
import Diagnostic from "./Diagnostic"
import Destination from "./Destination"
import Discharge from "./Discharge"
import Utils from "../utils"
import PatientsList from "./PatientsList"
import ToggleSwitch from "./ToggleSwitch"
import Reasons from "./Reasons"
import {
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Bar,
    Tooltip,
} from "recharts"
import DashVerticalBarChart from "./DashVerticalBarChart"

const CustomChartLabel = ({ x, y, fill, value, ...props }) => {
    // console.log({ x, y, fill, props })
    return (
        <text
            x={x}
            y={y}
            dy={-4}
            fontSize={12}
            fontFamily="sans-serif"
            fill={fill}
            textAnchor="middle"
        >
            {value}
        </text>
    )
}

const Dashboard = ({ formData, supabase }) => {
    const defaultAlert = { variant: "danger", msg: "" }

    const allRegions = { rgn_id: "", rgn_na: "---Todas---" }
    const allHospitals = { rgn_id: "", hosp_id: "", hosp_na: "---Todos---" }

    const [masterKey, setMasterKey] = useState(1)
    const [tab, setTab] = useState("form")
    const [regions, setRegions] = useState([...formData.regions])
    const [region, setRegion] = useState({})
    const [hospitals, setHospitals] = useState([...formData.hospitals])
    const [hospital, setHospital] = useState({})
    // const [patientName, setPatientName] = useState({})
    // const [patientAge, setPatientAge] = useState({})
    // const [transportation, setTransportation] = useState({})
    // const [insurer, setInsurer] = useState({})
    // const [reason, setReason] = useState({})
    // const [arrivalDatetime, setArrivalDatetime] = useState({})
    // const [triageStartDatetime, setTriageStartDatetime] = useState({})
    // const [triageEndDatetime, setTriageEndDatetime] = useState({})
    // const [risk, setRisk] = useState({})
    // const [diagnostic, setDiagnostic] = useState({})
    // const [triggerCheck, setTriggerCheck] = useState(0)
    // const [destination, setDestination] = useState({})
    // const [discharge, setDischarge] = useState({})
    // const [dischargeDatetime, setDischargeDatetime] = useState({})
    // const [alert, setAlert] = useState(defaultAlert)
    // const [dischargeLater, setDischargeLater] = useState(false)
    // const [pendingCount, setPendingCount] = useState("...")
    const [data, setData] = useState({ ...formData })
    const [statsPrimaryDiagnostic, setStatsPrimaryDiagnostic] = useState([])
    const [statsSecondaryDiagnostic, setStatsSecondaryDiagnostic] = useState([])

    const arrivalLabel = "Ingreso a Sala"
    const triageStartLabel = "Inicio de Triaje"
    const triageEndLabel = "Finalización de Triaje"

    // const clear = () => {
    //     setMasterKey(k => ++k)
    //     setPatientName({})
    //     setPatientAge({})
    //     setTransportation({})
    //     setInsurer({})
    //     setReason({})
    //     setArrivalDatetime({})
    //     setTriageStartDatetime({})
    //     setTriageEndDatetime({})
    //     setRisk({})
    //     setDiagnostic({})
    //     setTriggerCheck(0)
    //     setDestination({})
    //     setDischarge({})
    //     setDischargeDatetime({})
    //     setDischargeLater(false)
    //     setAlert(defaultAlert)
    // }

    const getStats = async (functionName, setCallback) => {
        console.log([region.rgn_id, hospital.hosp_id])
        let { data, status, statusText, error } = await supabase.rpc(
            functionName,
            {
                p_rgn_id: region.rgn_id === "" ? null : region.rgn_id,
                p_hosp_id: hospital.hosp_id === "" ? null : hospital.hosp_id,
                p_date_since: null,
                p_date_until: null,
            }
        )
        if (error) {
            console.error(error)
            return
        }
        setCallback(data.slice(0, 20))
        console.log(data)
    }

    const updateDashboard = async () => {
        await getStats("diag_primary_stats_fun", setStatsPrimaryDiagnostic)
        await getStats("diag_secondary_stats_fun", setStatsSecondaryDiagnostic)
    }

    const clearStats = () => {
        setStatsPrimaryDiagnostic([])
        setStatsSecondaryDiagnostic([])
    }

    const updateRegions = () => {
        let data2 = { ...formData }
        if (data2.regions.filter((x, i) => x.rgn_id === "")[0] === undefined) {
            data2.regions.unshift(allRegions)
        }
        setRegions([...data2.regions])
    }

    const updateHospitals = () => {
        let data2 = { ...formData }
        if (
            data2.hospitals.filter((x, i) => x.hosp_id === "")[0] === undefined
        ) {
            data2.hospitals.unshift(allHospitals)
        }
        setHospitals([...data2.hospitals])
    }

    const handleRegion = r => {
        if (r.rgn_id === undefined) {
            setHospitals([])
            setHospital({})
        } else if (r.rgn_id === "") {
            updateHospitals()
        } else {
            let list = data.hospitals.filter(
                x => x.rgn_id === r.rgn_id || x.hosp_id === ""
            )
            setHospitals(list)
        }
        setRegion(r)
    }

    const handleHospital = async h => {
        if (h === undefined) {
            return
        }
        else if (h.hosp_id === undefined) {
            clearStats()
        } else {
            await updateDashboard()
        }
    }

    useEffect(() => {
        handleHospital(hospital)
    }, [hospital])

    // useEffect(() => {
    // }, [region])

    useEffect(() => {
        setMasterKey(k => ++k)
    }, [statsPrimaryDiagnostic])

    useEffect(() => {
        updateRegions()
        updateHospitals()
    }, [])

    return (
        <Div show={true} className="px-4 py-2 mb-5 flex-grow-1">
            <Regions data={regions} onSelection={handleRegion} />

            <Hospitals2
                hide={region.rgn_id === undefined}
                data={hospitals}
                onSelection={setHospital}
            />
            <Div show={hospital.hosp_id !== undefined}>
                <div>Indicadores...</div>
                <button className="btn btn-primary" onClick={updateDashboard}>
                    Get
                </button>
                <DashVerticalBarChart
                    key={`pri_diag_chart_${masterKey}`}
                    title="Diagnóstico Primario"
                    data={statsPrimaryDiagnostic}
                    dataKeyX="cnt"
                    dataKeyY="diag_pri_na"
                />
                <DashVerticalBarChart
                    key={`sec_diag_chart_${masterKey}`}
                    title="Diagnóstico Secundario"
                    data={statsSecondaryDiagnostic}
                    dataKeyX="cnt"
                    dataKeyY="diag_sec_na"
                />
            </Div>
        </Div>
    )
}

export default Dashboard
