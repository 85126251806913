/** @format */

import React, { useState, useEffect } from "react"
import Regions from "./Regions"
import Button from "react-bootstrap/Button"
import Hospitals2 from "./Hospitals2"
import PatientName from "./PatientName"
import PatientAge from "./PatientAge"
import Transportation from "./Transportation"
import Insurers from "./Insurers"
import Div from "./Div"
import FormLabel from "./FormLabel"
import Datetime from "./Datetime"
import Risks from "./Risks"
import Diagnostic from "./Diagnostic"
import Destination from "./Destination"
import Discharge from "./Discharge"
import Utils from "../utils"
import PatientsList from "./PatientsList"
import ToggleSwitch from "./ToggleSwitch"
import Reasons from "./Reasons"

const SnsForm = ({ formData, supabase }) => {
    const defaultAlert = { variant: "danger", msg: "" }

    const [masterKey, setMasterKey] = useState(1)
    const [tab, setTab] = useState('form')
    const [region, setRegion] = useState({})
    const [hospitals, setHospitals] = useState([])
    const [hospital, setHospital] = useState({})
    const [patientName, setPatientName] = useState({})
    const [patientAge, setPatientAge] = useState({})
    const [transportation, setTransportation] = useState({})
    const [insurer, setInsurer] = useState({})
    const [reason, setReason] = useState({})
    const [arrivalDatetime, setArrivalDatetime] = useState({})
    const [triageStartDatetime, setTriageStartDatetime] = useState({})
    const [triageEndDatetime, setTriageEndDatetime] = useState({})
    const [risk, setRisk] = useState({})
    const [diagnostic, setDiagnostic] = useState({})
    const [triggerCheck, setTriggerCheck] = useState(0)
    const [destination, setDestination] = useState({})
    const [discharge, setDischarge] = useState({})
    const [dischargeDatetime, setDischargeDatetime] = useState({})
    const [alert, setAlert] = useState(defaultAlert)
    const [dischargeLater, setDischargeLater] = useState(false)
    const [pendingCount, setPendingCount] = useState('...')

    const arrivalLabel = "Ingreso a Sala"
    const triageStartLabel = "Inicio de Triaje"
    const triageEndLabel = "Finalización de Triaje"

    const post = async (payload) => {
        let { error, ...resp } = await supabase
            .from('patient_log')
            .insert([payload], { count: 'exact' })
        if (error) throw error
        return resp
    }

    const clear = () => {
        setMasterKey(k => ++k)
        setPatientName({})
        setPatientAge({})
        setTransportation({})
        setInsurer({})
        setReason({})
        setArrivalDatetime({})
        setTriageStartDatetime({})
        setTriageEndDatetime({})
        setRisk({})
        setDiagnostic({})
        setTriggerCheck(0)
        setDestination({})
        setDischarge({})
        setDischargeDatetime({})
        setDischargeLater(false)
        setAlert(defaultAlert)
    }

    const handleSend = () => {
        setTriggerCheck(t => ++t)
        let cols = [
                patientName, patientAge, transportation, insurer, arrivalDatetime, 
                triageStartDatetime, triageEndDatetime, risk, diagnostic, destination, 
                discharge, dischargeDatetime
            ]
        let isValid = cols.reduce((p,c) => {
                // if (c && !c.isValid) console.log(c)
                return c && c.isValid && p
            }, true)
        if (isValid) {
            let payload = {
                rgn_id: region.rgn_id,
                hosp_id: hospital.hosp_id,
                p_name: patientName.value,
                p_age_yr: patientAge.period === 12 ? parseInt(patientAge.value) : 0,
                p_age_mth: patientAge.period === 1 ? parseInt(patientAge.value) : 0,
                trans_id: transportation.trans_id,
                ins_id: insurer.ins_id,
                rsn_id: reason.rsn_id,
                arrival_ts: arrivalDatetime.date.toISOString(),
                triage_beg_ts: triageStartDatetime.date.toISOString(),
                triage_end_ts: triageEndDatetime.date.toISOString(),
                risk_id: risk.risk_id,
                diag_pri_cd: diagnostic.primary.diag_pri_cd,
                diag_sec_cd: diagnostic.secondary.diag_sec_cd,
                dest_id: destination.dest_id,
                discharge_ts: dischargeDatetime.date ? dischargeDatetime.date.toISOString() : null,
                disc_id: discharge.disc_id,
            }

            if (insurer.ins_id >= 98 && insurer.other) payload.other_ins_na = insurer.other;
            if (reason.rsn_id >= 98 && reason.other) payload.other_rsn_na = reason.other;
            // console.log(payload)
            setAlert({ variant: "secondary", msg: `Campos válidos. Eviando...` })
            
            // setTimeout(() => {setAlert(defaultAlert)}, 3000)
            post(payload)
            .then(data => {
                setAlert({ variant: "success", msg: `Formulario enviado exitosamente` })
                updatePendingCount()
                setTimeout(() => {
                    setAlert(defaultAlert)
                    clear()
                }, 1000)
            })
            .catch(error => {
                console.error(error)
                setAlert({ variant: "danger", msg: `Error enviando el formulario` })
            })

        } else setAlert({ variant: "danger", msg: "Campos inválidos. Favor corregir." })
    }

    const handleDischargeLaterChange = val => {
        // console.log(val)
        setDischargeLater(val)
        setDischargeDatetime({ isValid: true })
        setDischarge({ isValid: true })
    }

    const updatePendingCount = () => {
        supabase
        .from('patient_pending_vw')
        .select('p_cnt')
        .eq('hosp_id', hospital.hosp_id)
        .then(({ data, error }) => {
            if (error) {
                console.log(error)
            }
            else {
                let count = data[0]
                if (count && count.p_cnt !== undefined) {
                    setPendingCount(count.p_cnt)
                }
            }
        })
    }

    useEffect(() => {
        if (region.rgn_id === undefined) {
            setHospitals([])
            setHospital([])
        }
        else {
            let list = formData.hospitals.filter(x => x.rgn_id === region.rgn_id)
            setHospitals(list)
        }
    }, [region])

    useEffect(() => {
        if (hospital.hosp_id === undefined) {
            setPendingCount('...')
        }
        else {
            updatePendingCount()
        }
    }, [hospital])

    return (
        <Div show={true} className="px-4 py-2 mb-5 flex-grow-1">
            <Regions 
                data={formData.regions} 
                onSelection={setRegion}
                />
            
            <Hospitals2
                hide={hospitals.length === 0 }
                data={hospitals}
                onSelection={setHospital}
            />
            <Div show={hospital.hosp_id !== undefined}>
                <div className="mt-4">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a 
                                className={`nav-link ${tab === "form" ? "active" : ""}`} 
                                href="#" 
                                onClick={() => setTab('form')}
                            >
                                Formulario
                            </a>
                        </li>
                        <li className="nav-item">
                            <a 
                                className={`nav-link ${tab === "pend" ? "active" : ""}`} 
                                href="#" 
                                onClick={() => setTab('pend')} 
                            >
                                {`Pendientes de Egreso (${pendingCount})`}
                            </a>
                        </li>
                    </ul>
                </div>
                <Div show={tab==='form'} className="p-2 pb-4 mb-2 bg-white border border-scondary border-top-0">
                    <FormLabel label="Datos del Paciente" className="px-2 pt-3" required />
                    <PatientName
                        key={`name_${masterKey}`}
                        onChange={setPatientName}
                        triggerCheck={triggerCheck}
                        // initValue={patientName.value}
                        />
                    <PatientAge
                        key={`age_${masterKey}`}
                        onChange={setPatientAge}
                        triggerCheck={triggerCheck}
                        // initValue={patientAge.value}
                    />
                    <Transportation
                        key={`trans_${masterKey}`}
                        data={formData.transportation}
                        onSelection={setTransportation}
                        triggerCheck={triggerCheck}
                        />
                    <Insurers
                        key={`insurer_${masterKey}`}
                        data={formData.insurers}
                        onSelection={setInsurer}
                        triggerCheck={triggerCheck}
                    />
                     <Reasons
                        key={`reason_${masterKey}`}
                        data={formData.reasons}
                        onSelection={setReason}
                        triggerCheck={triggerCheck}
                    />
                    <Datetime
                        key={`dt_arrival_${masterKey}`}
                        label={arrivalLabel}
                        onSelection={setArrivalDatetime}
                        required
                        triggerCheck={triggerCheck}
                    />
                    <Datetime
                        key={`dt_tri_start_${masterKey}`}
                        label={triageStartLabel}
                        onSelection={setTriageStartDatetime}
                        date={arrivalDatetime.date}
                        depLabel={arrivalLabel}
                        minTimeDifference="15m"
                        count={2}
                        offset={1}
                        required
                        triggerCheck={triggerCheck}
                    />
                    <Datetime
                        key={`dt_tri_end_${masterKey}`}
                        label={triageEndLabel}
                        onSelection={setTriageEndDatetime}
                        date={triageStartDatetime.date}
                        depLabel={triageStartLabel}
                        minTimeDifference="15m"
                        count={2}
                        offset={1}
                        required
                        triggerCheck={triggerCheck}
                    />
                    <Risks
                        key={`risk_${masterKey}`}
                        data={formData.risks}
                        onSelection={setRisk}
                        triggerCheck={triggerCheck}
                    />
                    <Diagnostic
                        key={`diag_${masterKey}`}
                        label="Diagnóstico"
                        data={formData.diag_primary}
                        data2={formData.diag_secondary}
                        onSelection={setDiagnostic}
                        triggerCheck={triggerCheck}
                    />
                    <Destination
                        key={`desst_${masterKey}`}
                        data={formData.destinations}
                        onSelection={setDestination}
                        triggerCheck={triggerCheck}
                    />
                    <ToggleSwitch 
                        show={true}
                        label="¿Dar Egreso más tarde?"
                        checked={dischargeLater}
                        onChange={handleDischargeLaterChange}
                    />
                    <Datetime
                        hide={dischargeLater}
                        key={`dt_discharge_${masterKey}`}
                        label="Egreso"
                        onSelection={setDischargeDatetime}
                        date={triageEndDatetime.date}
                        depLabel={triageEndLabel}
                        minTimeDifference="24h"
                        count={2}
                        offset={1}
                        required
                        triggerCheck={triggerCheck}
                    />
                    <Discharge
                        hide={dischargeLater}
                        key={`disc_${masterKey}`}
                        data={formData.discharge}
                        onSelection={setDischarge}
                        triggerCheck={triggerCheck}
                    />
                    <br/>
                    <Div show={alert.msg} className={`alert alert-${alert.variant}`}>
                        {alert.msg}
                    </Div>
                    <div className="p-2 d-flex justify-content-end">
                        <Button 
                            className="btn-danger me-2"
                            onClick={clear}
                        >
                            Limpiar
                        </Button>
                        <Button 
                            className="px-4"
                            onClick={handleSend}
                        >
                            Enviar
                        </Button>
                    </div>
                </Div>
                <Div show={tab==='pend'} className="p-2 pb-4 mb-2 bg-white border border-scondary border-top-0">
                    <PatientsList
                        supabase={supabase}
                        hospitalId={hospital.hosp_id}
                        formData={formData}
                        onUpdate={() => updatePendingCount()}
                    />
                </Div>
            </Div>
        </Div>
    )
}

export default SnsForm
