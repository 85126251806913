/** @format */

import React, { useState, useCallback } from "react"
import Input from "./Input"

const NumberInput = ({ label, placeholder, onChange, minValue, maxValue, disabled, initValue, noLabel, noFeedback, triggerCheck, ...props }) => {
    if (minValue === undefined) minValue = 1
    maxValue = maxValue || 120
    
    const checkValueBetween = (val, low, high) => {
        val = parseInt(val)
        if (val >= low && val <= high) {
            return { isValid: true }
        }
        else {
            return { isValid: false, msg: `Favor introduzca número entre ${low} y ${high}` }
        }
    }

    const checkValidity = useCallback(val => checkValueBetween(val, minValue, maxValue), [minValue, maxValue])

    return (
        <Input
            show={true}
            type="text"
            allowedKeysTest={key => /[0-9]/.test(key)}
            checkValidity={checkValidity}
            label={label}
            placeholder={placeholder}
            initValue={initValue}
            onChange={onChange}
            disabled={disabled}
            noLabel={noLabel}
            noFeedback={noFeedback}
            triggerCheck={triggerCheck}
            // lockValue
            {...props}
        />
    )
}

export default NumberInput
