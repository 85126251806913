/** @format */

import React from "react"
import TextDropdown from "./TextDropdown"

const Transportation = ({ data, onSelection, disabled, triggerCheck, ...props }) => {
    return (
        <TextDropdown 
            data={data}
            onSelection={onSelection}
            idColumn="trans_id"
            nameColumn="trans_ty"
            label="Medio de Llegada"
            disabled={disabled}
            triggerCheck={triggerCheck}
            required
            {...props}
        />
    )
}

export default Transportation
