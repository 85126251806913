/** @format */

import React, { useState, useEffect } from "react"
import Div from "./Div"
import Button from "react-bootstrap/Button"

const Modal = ({
    show,
    onDismiss,
    onApply,
    dismissLabel,
    applyLabel,
    classsName,
    children,
}) => {
    onDismiss = onDismiss || (() => {})
    onApply = onApply || (() => {})

    // const [showModal, setShowModal] = useState(show)

    const handleDismiss = () => {
        // setShowModal(false)
        onDismiss(false)
    }

    const handleApply = () => {
        // setShowModal(false)
        onApply(true)
    }

    // useEffect(() => {
    //     setShowModal(show)
    // }, [show])

    if (!show) return <></>
    return (
        <Div 
            show={true} 
            className={"am-modal"} 
            style={{ position: "fixed" }}
            onClickOutside={handleDismiss}
        >
            <div
                className={
                    classsName ||
                    `am-modal-container d-flex flex-column justify-content-between`
                }
            >
                <div>{children}</div>
                <div className="d-flex flex-row justify-content-end">
                    <Button 
                        variant="dark"
                        onClick={handleDismiss}
                    >
                        {dismissLabel || "Dismiss"}
                    </Button>
                    <Button 
                        variant="primary"
                        className="ms-2"
                        onClick={handleApply}
                    >
                        {applyLabel || "Ok"}
                    </Button>
                </div>
            </div>
        </Div>
    )
}

export default Modal
