/** @format */

import React from "react"
import TextInput from "./TextInput"

const PatientName = ({ onChange, initValue, triggerCheck, ...props }) => {
    return (
        <TextInput
            label="Nombre del Paciente"
            placeholder="nombre"
            onChange={onChange}
            initValue={initValue}
            triggerCheck={triggerCheck}
            noLabel
            {...props}
        />
    )
}

export default PatientName
