/** @format */

import React from "react"

import FormLabel from "./FormLabel"
import Div from "./Div"
import Alert from "./Alert"

const FormGroup = ({
    type,
    label,
    labelClassName,
    alert,
    invalid,
    children,
    required,
    ...props
}) => {
    type = type || "row"
    return (
        <div className="d-flex flex-column align-items-stretch">
            <FormLabel
                label={label}
                className={labelClassName}
                required={required}
            />
            <Div show={true} className={`d-flex flex-${type}`}>
                {children}
            </Div>
            <Alert variant="warning" alert={alert} />
            <Alert variant="danger" alert={invalid} />
        </div>
    )
}

export default FormGroup
