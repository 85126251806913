/** @format */

import React, { useEffect, useState } from "react"
import FormGroup from "./FormGroup"
import Input from "./Input"
import TextDropdown from "./TextDropdown"
import TextInput from "./TextInput"

const Insurers = ({ data, onSelection, disabled, triggerCheck, ...props }) => {
    const [insurer, setInsurer] = useState({})
    const [showOther, setShowOther] = useState(false)

    const label = "Seguro Médico"

    onSelection = onSelection || (() => {})

    const handleSelection = item => {
        if (item && item.ins_id >= 98) {
            setShowOther(true)
            item.isValid = false
        } else {
            setInsurer(i => ({...i, other: undefined}))
            setShowOther(false)
        }
        setInsurer(item)
    }

    const handleOtherChange = item => {
        if (!item || !item.isValid) {
            if (insurer.ins_id >= 98) setInsurer(i => ({...i, other: item.value, isValid: false}))
        }
        else setInsurer(currentInsurer => {
            let newInsurer = { ...currentInsurer }
            newInsurer.isValid = true
            newInsurer.other = item.value
            return newInsurer
        })
    }

    useEffect(() => {
        onSelection(insurer)
    }, [insurer])

    return (
        <FormGroup type="column" label={label} labelClassName="px-2 pt-3" required >
            <TextDropdown
                data={data}
                label={label}
                onSelection={handleSelection}
                // idColumn="ins_id"
                nameColumn="ins_na"
                disabled={disabled}
                sort={true}
                triggerCheck={triggerCheck}
                noLabel
                {...props}
            />
            <TextInput
                hide={!showOther}
                placeholder="Especifique..."
                onChange={handleOtherChange}
                minLength={3}
                triggerCheck={triggerCheck}
                noLabel
            />
        </FormGroup>
    )
}

export default Insurers
