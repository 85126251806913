/** @format */

import React, { useState, useEffect } from "react"
import Input from "./Input"

const TextInput = ({ label, placeholder, initValue, onChange, minLength, disabled, noLabel, noFeedback, triggerCheck, hide, ...props }) => {
    minLength = minLength || 5

    const checkValidity = val => {
        if (val.length >= minLength) {
            return { isValid: true }
        }
        else {
            return { isValid: false, msg: `Favor introduzca por lo menos ${minLength} caracteres` }
        }
    }

    return (
        <Input
            show={hide ? false : true}
            type="text"
            allowedKeysTest={key => /[A-Za-z\s]/.test(key)}
            checkValidity={checkValidity}
            label={label}
            placeholder={placeholder}
            initValue={initValue}
            onChange={onChange}
            disabled={disabled}
            valueFormatter={val => val.toUpperCase()}
            noLabel={noLabel}
            noFeedback={noFeedback}
            triggerCheck={triggerCheck}
            {...props}
        />
    )
}

export default TextInput
