/** @format */

import React from "react"
import TextDropdown from "./TextDropdown"

const Risks = ({ data, onSelection, disabled, triggerCheck, ...props }) => {
    return (
        <TextDropdown 
            data={data}
            onSelection={onSelection}
            idColumn="risk_id"
            nameColumn="risk_na"
            label="Clasificación de Riesgo"
            disabled={disabled}
            sort={true}
            triggerCheck={triggerCheck}
            required
            {...props}
        />
    )
}

export default Risks
