/** @format */

import React from "react"
import { FiAlertTriangle, FiXCircle } from "react-icons/fi"
import Div from "./Div"

const Alert = ({ variant, alert }) => {
    variant = variant || "warning"
    return (
        <Div show={alert ? true : false} className="px-2">
            <div className={`px-2 py-1 fs-sm alert alert-${variant}`}>
                <span className="px-2">
                    { variant === 'warning' && <FiAlertTriangle /> }
                    { variant === 'danger' && <FiXCircle /> }
                </span>
                <span>{alert}</span>
            </div>
        </Div>
    )
}

export default Alert
