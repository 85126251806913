/** @format */

import React, { useState, useRef, useEffect } from "react"
import Colors from "../colors"

function useOutsideAlerter(ref, cb, cbKeyUp) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
        //   console.log("You clicked outside of me!");
          cb()
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
    };
    }, [ref]);
  }

const Div = ({
    show,
    children,
    style,
    textColor,
    backgroundColor,
    tooltip,
    onClickOutside,
    ...props
}) => {
    style = style || {}
    style = { position: "relative", ...style }
    if (textColor) style = { ...style, color: textColor }
    if (backgroundColor) style = { ...style, backgroundColor }

    const [showTooltip, setShowTooltip] = useState(false)
    const [tooltipStyle, setTooltipStyle] = useState({})

    const tooltipRef = useRef()
    const childrenRef = useRef()

    onClickOutside = onClickOutside || (() => {})
    useOutsideAlerter(childrenRef, onClickOutside);

    useEffect(() => {
        if (!tooltipRef.current || !tooltip) return
        let tooltipDims = tooltipRef.current.getBoundingClientRect()
        let childrenDims = childrenRef.current.getBoundingClientRect()
        let widthDifference = tooltipDims.width - childrenDims.width
        setTooltipStyle(currStyle => ({ ...currStyle, right: widthDifference / 2 * - 1 }))
    }, [showTooltip])

    if (!show) return <></>
    return (
        <div
            ref={childrenRef}
            style={style}
            onMouseEnter={() => setShowTooltip(true && tooltip)}
            onMouseLeave={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            {...props}
        >
            {showTooltip && (
                <div
                    ref={tooltipRef}
                    className="gr-div-tooltip"
                    style={tooltipStyle}
                >
                    <span className="text">
                        {tooltip}
                    </span>
                </div>
            )}

            {children}
        </div>
    )
}

export default Div
