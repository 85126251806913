/** @format */

import React, { useState, useEffect, useMemo } from "react"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { MdClose } from 'react-icons/md'
import Dropdown from "./Dropdown"
import Input from "./Input"
import Utils from "../utils"

const TextDropdown = ({ data, idColumn, nameColumn, label, onSelection, onFocus, onClick, disabled, sort, triggerCheck, defaultValue, defaultInvalidText, hide, final, required, className, ItemDiv, maxHeight, modalStyle, modalClassName, wrap, ...props }) => {
    const [isValid, setIsValid] = useState(final||undefined)
    const [isInvalid, setIsInvalid] = useState(undefined)
    const [item, setItem] = useState(null)
    const [searchValue, setSearchValue] = useState(defaultValue||'')
    const [showDropdown, setShowDropdown] = useState(false)
    const [key, setKey] = useState({})

    onSelection = onSelection || (() => {})
    onFocus = onFocus || (() => {})
    onClick = onClick || (() => {})

    const items = useMemo(() => {
        let transformedData = data.map((e,i) => {
            if (idColumn === undefined) {
                e.item_value = `${e[nameColumn]}`
            }
            else {
                e.item_value = `${e[idColumn]} - ${e[nameColumn]}`
            }
            e.isValid = true
            e.id = e[idColumn] || ""
            e.name = e[nameColumn] || ""
            return e
        })
        if (sort) {
            transformedData = transformedData.sort((a, b) => Utils.compareStrings(a.item_value, b.item_value, "asc"))
        }
        return transformedData
    }, [data])

    const handleChange = item => {
        if (!item || (item && item.value === "" && !item.isValid)) {
            clear()
        } else setSearchValue(item.value||"")
        // setShowDropdown(true)
    }

    const handleSelection = datum => {
        // console.log(datum)
        if (datum === undefined) {
            setItem(null)
            setIsValid(false)
            onSelection({})
        } else {
            setItem(datum)
            setIsValid(true)
            setSearchValue(Utils.extractValue(datum))
            onSelection(datum)
        }
        setShowDropdown(false)
    }

    const clear = (hide) => {
        setSearchValue('')
        setItem(null)
        onSelection({})
        setIsValid(false)
        setShowDropdown(false)
    }

    const handleNoSelection = () => {
        clear()
        // setShowDropdown(false)
    }

    const handleClear = () => {
        clear()
        // setShowDropdown(true)
    }

    const handleFocus = e => {
        setShowDropdown(true)
        onFocus(e)
    }

    const handleClick = e => {
        setShowDropdown(true)
        onClick(e)
    }

    // useEffect(() => {
    //     setSearchValue('')
    //     onSelection({})
    // }, [data])

    useEffect(() => {
        setIsValid(final||undefined)
    }, [final])

    useEffect(() => {
        setSearchValue(defaultValue||"")
    }, [defaultValue])

    if (hide) return <></>
    return (
        <div className={className}>
            <Input
                show={true}
                type="text"
                label={label}
                // value={value}
                initValue={searchValue}
                onChange={handleChange}
                placeholder={`Seleccionar ${label}`}
                disabled={data === undefined || data.length === 0 || disabled}
                onFocus={handleFocus}
                onClick={handleClick}
                onClear={handleClear}
                onKeyDown={setKey}
                // checkValidity={() => ({ isValid: isValid })}
                final={isValid}
                triggerCheck={triggerCheck}
                defaultInvalidText={defaultInvalidText||`Seleccione ${label}`}
                wrap={wrap}
                required={required}
                lockValue
                {...props}
            />
            <Dropdown
                show={showDropdown}
                data={items}
                searchValue={searchValue}
                onSelection={handleSelection}
                onNoSelection={handleNoSelection}
                keyAction={key}
                ItemDiv={ItemDiv}
                maxHeight={maxHeight}
                modalClassName={modalClassName}
                modalStyle={modalStyle}
                noButton
                expanded
                />
        </div>
    )
}

export default TextDropdown
