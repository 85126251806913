/** @format */

import React, { useState } from "react"

import Utils from "../utils"

const DiagnosticItem = ({ item, active, className, onClick, ...props }) => {
    const [ isHover, setIsHover ] = useState(false)

    onClick = onClick || (() => {})

    className = className || "item px-2 py-1 m-0 d-flex flex-column justify-content-center"
    className += active ? " active" : ""

    let idClassName = "noselect"
    idClassName += isHover ? " item-id-hover" : " item-id"
    idClassName += active ? " active" : ""
    return (
        <div
            className={className}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={onClick}
        >
            <div 
                className={idClassName}
            >
                {item.id}
            </div>
            <div className="item-name">{item.name}</div>
        </div>
    )
}

export default DiagnosticItem
