/** @format */

import "./App.css"
import { useState, useEffect } from "react"
import Container from "react-bootstrap/Container"
import { supabase } from "./supabaseClient"
import { get, set, update, del, clear } from "idb-keyval"
import SnsForm from "./components/SnsForm"
import SignIn from "./components/SignIn"
import Utils from "./utils"
import Dashboard from "./components/Dashboad"
import { MdOutlineDashboard } from "react-icons/md"
import { IconContext } from 'react-icons'

global.clearCache = () => {
    Utils.deleteAllCookies()
    localStorage.clear()
    clear().then(() => console.log("IDB KeyVal cache cleared"))
}

function App() {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({})
    const [signedIn, setSignedIn] = useState(false)
    const [goToDashboard, setGoToDashboard] = useState(false)

    const fetchFromSupabase = async (uri, prop) => {
        let { data, error } = await supabase.from(uri).select("*")
        if (error) throw error
        let dt = new Date()
        let ts = Utils.floor(dt.getTime() / 1000)
        let updated = dt.toISOString()
        let expires = ts + 60 * 60 // ttl = 1hr
        await set(prop, { data, updated, expires })
        return data
    }

    const fetchData = async (uri, prop) => {
        prop = prop || uri

        let { data, updated, expires } = await get(prop) ?? {}
        let dt = new Date()
        let stale = false
        
        if (expires < Utils.floor(dt.getTime() / 1000)) {
            stale = true
        } else if (expires === undefined) stale = true

        if (data === undefined || stale) {
            data = await fetchFromSupabase(uri, prop)
        }

        setFormData(d => {
            d[prop] = data
            return d
        })
        return true
    }

    const checkSignIn = async () => {
        let { data: { session } } = await supabase.auth.getSession()
        if (session && session.user && session.access_token) {
            if (session.user.email === 'indicadores.sns.admin@protonmail.com') {
                setGoToDashboard(true)
            }
            else {
                setGoToDashboard(false)
            }
            setSignedIn(true)
        } else setSignedIn(false)
    }

    const handleSignOut = async () => {
        await supabase.auth.signOut()
        await clear()
        setSignedIn(false)
    }

    const openPBI = () => {
        // window.open(`${document.URL}/dash.html`, '_blank', 'location=no,height=768,width=768,scrollbars=yes,status=yes');
        let iframe = `
        <html>
            <head>
                <meta charset="utf-8" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <title>Indicadores SNS</title>
                <style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style>
            </head>
            <body>
                <iframe
                    title="Indicadores salas de emergencia"
                    style="height:calc(100% - 4px);width:calc(100% - 4px)"
                    src="https://app.powerbi.com/view?r=eyJrIjoiMDg2OTg0YWEtN2Q2My00ZGJlLWJjYmYtMTQzZmEzOGZkNTgyIiwidCI6IjAyYTY0NmMxLWRhMDQtNGU1MS1hYzNiLTNhZjYxN2FkY2E0YiIsImMiOjJ9"
                    frameborder="0"
                    allowfullscreen="true"
                ></iframe>
            </body>
        </html>
        `;
        let win = window.open('', '', 'location=no,height=768,width=768,scrollbars=yes,status=yes,toolbar=no,menubar=no,resizable=yes')
        win.document.write(iframe)
    }

    useEffect(() => {
        if (isLoading || !signedIn) return
        setIsLoading(true)
        fetchData("regions")
            .then(() => fetchData("hospitals"))
            .then(() => fetchData("transportation"))
            .then(() => fetchData("insurers"))
            .then(() => fetchData("reasons"))
            .then(() => fetchData("risks"))
            .then(() => fetchData("diag_primary"))
            .then(() => fetchData("diag_secondary"))
            .then(() => fetchData("destinations"))
            .then(() => fetchData("discharge"))
            .then(() => setIsLoaded(true))
            .catch(err => setError(err))
        return () => setIsLoading(false)
    }, [signedIn])

    useEffect(() => {
        checkSignIn()
    },[])

    if (error) return <div className="p-2 alert alert-danger font-monospace">Error : {error.message}</div>
    
    if (!signedIn) {
        return (
            <div className="p-0 d-flex flex-row justify-content-center sign-in-container">
                <SignIn supabase={supabase} onSignIn={() => checkSignIn()} />
            </div>
        )
    }
    else if (!isLoaded) {
        return (
            <div className="loading">
                <div className="spinner"></div>
                <div>Cargando...</div>
            </div>
        )
    }
    else if (goToDashboard) {
        return (
            <Container id="main-dash" className="p-0 d-flex flex-column justify-content-start">
                <div className="p-2 bg-dark shadow d-flex justify-content-between">
                    <div className="px-3 py-2 btn btn-primary " onClick={openPBI}>
                        <IconContext.Provider value={{ className: "shared-class", size: 22 }}>
                            <MdOutlineDashboard />
                        </IconContext.Provider>
                        <span className="ps-2">Indicadores</span>
                    </div>
                    <div className="px-3 py-2 btn btn-danger" onClick={handleSignOut}>
                        Salir
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-between flex-grow-1">
                    <Dashboard
                        formData={formData}
                        supabase={supabase}
                    />
                    <div className="bg-dark" style={{paddingBottom: "15rem"}}></div>
                </div>
            </Container>
        )
    }
    return (
        <Container id="main" className="p-0 d-flex flex-column justify-content-start">
            <div className="p-2 bg-dark shadow d-flex justify-content-between">
                <div className="px-3 py-2 btn btn-primary " onClick={openPBI}>
                    <IconContext.Provider value={{ className: "shared-class", size: 22 }}>
                        <MdOutlineDashboard />
                    </IconContext.Provider>
                    <span className="ps-2">Indicadores</span>
                </div>
                <div className="px-3 py-2 btn btn-danger" onClick={handleSignOut}>
                    Salir
                </div>
            </div>
            <div className="d-flex flex-column justify-content-between flex-grow-1">
                <SnsForm
                    formData={formData}
                    supabase={supabase}
                />
                <div className="bg-dark" style={{paddingBottom: "15rem"}}></div>
            </div>
        </Container>
    )
}

export default App
