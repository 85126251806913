/** @format */

import React, { useState, useEffect } from "react"
import Input from "./Input"
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup"
import ToggleButton from "react-bootstrap/ToggleButton"

const TimeInput = ({ name, label, placeholder, initValue, onChange, disabled, triggerCheck, ...props }) => {

    onChange = onChange || (() => {})
    const [ item, setItem ] = useState({tod:"AM"})
    const [ timeOfDay, setTimeOfDay ] = useState("AM")

    const options = [
        { name: "AM", value: "AM" },
        { name: "PM", value: "PM" },
    ]

    const checkValidity = val => {
        let hour = parseInt(val.split(':')[0])
        let minutes = parseInt(val.split(':')[1])
        if (hour > 0 && hour <= 12 && minutes >= 0 && minutes < 60) {
            return { isValid: true }
        }
        else {
            return { isValid: false, msg: `Hora invalida` }
        }
    }

    const valueFormatter = val => {
        val = val.replace(':','').substr(-4)
        val = val.padStart(4,'0')
        return `${val.substr(0,2)}:${val.substr(-2)}`
    }

    const getHours = (item) => {
        let obj = {
            hours: -1,
            minutes: -1,
            timeDelta: -1
        }
        if (item.isValid === true && item.value) {
            obj.hours = parseInt(item.value.substr(0,2)) % 12 + (item.tod === 'PM' ? 12 : 0)
            obj.minutes = parseInt(item.value.substr(-2))
            obj.time = obj.hours * 60 * 60 + obj.minutes * 60
        } 
        return obj
    }

    const updateItem = (time, tod) => {
        let newItem = { ...time, tod }
        newItem = { ...newItem, ...getHours(newItem) }
        return newItem
    }

    const handleTimeChange = newTime => {
        setItem(currTime => updateItem(newTime, currTime.tod))
    }
    
    const handleTodChange = newTod => {
        setTimeOfDay(newTod)
        setItem(currTime => updateItem(currTime, newTod))
    }

    useEffect(() => {
        if (item === undefined) return
        onChange(item)
    }, [item])
    
    return (
        <>
            <Input
                show={true}
                type="text"
                allowedKeysTest={key => /[0-9]/.test(key)}
                checkValidity={checkValidity}
                label={label}
                placeholder={placeholder}
                initValue={initValue}
                onChange={handleTimeChange}
                disabled={disabled}
                valueFormatter={valueFormatter}
                triggerCheck={triggerCheck}
                {...props}
            />
            <div className="p-2">
                <ToggleButtonGroup 
                    type="radio" 
                    name={`time-of-day-${name}`}
                    defaultValue={timeOfDay}
                    onChange={handleTodChange}
                >
                    {options.map((e, i) => (
                        <ToggleButton
                            key={`${name}_dt_opt_${i}`}
                            id={`${name}_dt_opt_${i}`}
                            variant="outline-primary"
                            value={e.value}
                        >
                            {e.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
        </>
    )
}

export default TimeInput
