/** @format */

import React from "react"
import TextDropdown from "./TextDropdown"

const Regions = ({ data, onSelection, ...props }) => {
    return (
        <TextDropdown 
            data={data}
            onSelection={onSelection}
            idColumn="rgn_id"
            nameColumn="rgn_na"
            label="Región"
            {...props}
        />
    )
}

export default Regions
