import React, { useState, useEffect, useRef, forwardRef } from 'react'
import SearchItem from './SearchItem'
import Cell from './Cell'
import Button from 'react-bootstrap/Button'
import Scrollbars from 'react-custom-scrollbars-2'
import Utils from '../utils'
import Colors from '../colors'

/**
 * Hook that alerts clicks outside of the passed ref
 * source: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
 function useOutsideAlerter(ref, cb, cbKeyUp) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
        //   console.log("You clicked outside of me!");
          cb()
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
    };
    }, [ref]);
  }

  const Dropdown = ({ show, data, placeholder, prefix, onSelection, onNoSelection, dontCloseOnSelection, noLabel, noButton, expanded, disabled, ItemDiv, Icon, keyAction, style, className, buttonClassName, buttonStyle, modalStyle, modalClassName, rounded, search, searchValue, tooltip, debug, maxHeight, ...props }) => {

    const [ activeItem, setActiveItem ] = useState(0)
    const [ label, setLabel ] = useState('')
    const [ showDropdown, setShowDropdown ] = useState(expanded && !disabled ? true: false)
    const [ searchText, setSearchText ] = useState(searchValue || '')
    const [ showTooltip, setShowTooltip ] = useState(false)
    const [ filteredData, setFilteredData ] = useState([])

    // let filteredData = data ? data.filter(e => (new RegExp(searchText, 'i').test(Utils.extractValue(e)))) : []

    if (placeholder === undefined && data) placeholder = Utils.extractValue(data[activeItem])

    if (debug) {
        console.log(placeholder, prefix)
        console.log(buttonStyle)
    }

    if (!rounded) rounded = "rounded"
    else if (rounded === 'start' || rounded === 'left') rounded = "rounded-0 rounded-start"
    else if (rounded === 'end' || rounded === 'right') rounded = "rounded-0 rounded-end"
    else if (rounded === 'top') rounded = "rounded-0 rounded-top"
    else if (rounded === 'bottom') rounded = "rounded-0 rounded-bottom"
    else if (rounded === 'none') rounded = "rounded-0"

    // modalStyle = { 
    //     top: "0rem", 
    //     left: "0rem",
    //     padding: "1.1rem 0.05rem 0.05rem 0.05rem",
    //     ...modalStyle 
    // }
    
    const updateActiveItem = e => {
        if (e.key === 'ArrowUp') {
            if (activeItem === 0) setActiveItem(filteredData.length - 1)
            else setActiveItem((activeItem - 1) % filteredData.length)
        } else if (e.key === 'ArrowDown') {
            setActiveItem((activeItem + 1) % filteredData.length)
        }
    }

    const getActiveItem = () => data && filteredData.length > 0 ? filteredData[activeItem] : null

    const onItemSelection = (e, idx) => {
        if(!noLabel) setLabel(Utils.extractValue(e))
        if(!expanded) setShowDropdown(false)
        setSearchText('')
        if (idx !== undefined) setActiveItem(idx)
        if (onSelection) onSelection(e)
    }

    const onClose = e =>{
        if(!expanded) setShowDropdown(false)
        if(onNoSelection) onNoSelection()
        setSearchText('')
    }
    
    const onKeyDown = e => {
        let item = null
        if(e === undefined) return
        switch (e.key) {
            case "Escape":
                onClose()
                break;
            case "Enter":
                item = getActiveItem()
                if (item === null || item === undefined) return
                if (activeItem + 1 > filteredData.length) return
                onItemSelection(item)
                break
            case "Tab":
                onClose()
                break;
            case "ArrowDown":
                updateActiveItem(e)
                break
            case "ArrowUp":
                updateActiveItem(e)
                break
            default:
                break;
        }
    }

    const onButtonClick = e => {
        setShowDropdown(!showDropdown && !disabled)
    }

    const onSearchChange = e => {
        setSearchText(e.target.value)
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, onClose);

    useEffect(() => {
        if(keyAction && keyAction.key) onKeyDown({ ...keyAction, value: Utils.extractValue(getActiveItem()) })
    }, [keyAction])

    useEffect(() => {
        setSearchText(searchValue || '')
    }, [searchValue])

    useEffect(() => {
        if (data) {
            setFilteredData(data.filter(e => (new RegExp(searchText, 'i').test(Utils.extractValue(e)))))
        }
        else setFilteredData([])
    }, [data, searchText])

    if (!show) return <></>
    else return (
    <>
        <div ref={wrapperRef} 
            className={className}
            style={{ position: "relative", padding: "0rem", }}
            onMouseEnter={() => setShowTooltip(true && tooltip)} 
            onMouseLeave={() => setShowTooltip(false)} 
            onBlur={() => setShowTooltip(false)}
            >
            {
                !noButton &&
                <Button 
                className={ buttonClassName || `btn-success ${rounded}` }
                style={buttonStyle} 
                onClick={onButtonClick}
                onKeyDown={e => onKeyDown(e)}
                >
                    { Icon ? <span className="mx-1"><Icon /></span> : <span></span> }
                    <span>{`${prefix && label ? `${prefix} ` : ''}${label || placeholder}`}</span>
                </Button>
            }

            {
                showTooltip &&
                <div
                    style={{
                        position: "absolute", 
                        top: "-1.2rem", 
                        backgroundColor: `${Colors.darker}BB`,
                        color: Colors.light,
                        zIndex: 999,
                        whiteSpace: "nowrap",
                        padding: "0.1rem 0.3rem 0.1rem 0.3rem",
                        borderRadius: "0.3rem",
                        }}>
                    <span className="text">{tooltip || 'Fucking tooltip!'}</span>
                </div>
            }

            <div style={{ position: "relative", padding: "0rem", overflowX: "none" }}>
            {
                showDropdown && (filteredData.length > 0 || props.children) &&
                <div className={modalClassName||"dropdown"} style={modalStyle} >
                    { search && <div><Cell placeholder="Search" value={searchText} onChange={onSearchChange} /></div> }
                    { props.children }
                    <Scrollbars autoHeight autoHeightMin={"3rem"} autoHeightMax={maxHeight||"60vh"} >
                    { 
                        data && filteredData.map((e,i,a) => {
                            let isActive = i === activeItem
                            let onClick = () => onItemSelection(e, i)
                            let key = `search_item_${i}`
                            if (!ItemDiv) {
                                return (
                                <SearchItem key={key} onClick={onClick} item={e} active={isActive}>
                                    <div style={{whiteSpace: "nowrap"}}>{Utils.extractValue(e)}</div>
                                </SearchItem>
                            )}
                            else return (
                                <ItemDiv key={key} item={e} active={isActive} onClick={onClick} />
                            )
                        })
                    }
                    </Scrollbars>
                </div>
            }
            </div>

        </div>
    </>
  )
}

export default Dropdown