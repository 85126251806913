/** @format */

import React from "react"
import TextDropdown from "./TextDropdown"

const Destination = ({ data, onSelection, disabled, triggerCheck, ...props }) => {
    return (
        <TextDropdown 
            data={data}
            onSelection={onSelection}
            idColumn="dest_id"
            nameColumn="dest_na"
            label="Destino en Sala de Emergencia"
            disabled={disabled}
            sort={true}
            triggerCheck={triggerCheck}
            required
            {...props}
        />
    )
}

export default Destination
