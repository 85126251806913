/** @format */

// const SHA256 = require("crypto-js/sha256")
// const CJS = require("crypto-js")
// const msgpack = require('@msgpack/msgpack')

import CJS from "crypto-js"
import { encode, decode } from "@msgpack/msgpack"

const msgpack = { encode, decode }

const ISO_DATE_REGEX = /^([12][0-9]{3})-([01][0-9])-([0-3][0-9])$/
const SHORT_DATE_REGEX = /^([01][0-9])\/([0-3][0-9])\/([12][0-9]{3})$/

const MONTHS = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
]

const MONTHS_ES = [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
]

const MONTHS_FULL = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

const MONTHS_FULL_ES = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
]

// https://stackoverflow.com/questions/23097928/node-js-throws-btoa-is-not-defined-error
global.Buffer = global.Buffer || require("buffer").Buffer

if (typeof btoa === "undefined") {
    global.btoa = function (str) {
        return new Buffer(str, "binary").toString("base64")
    }
}

if (typeof atob === "undefined") {
    global.atob = function (b64Encoded) {
        return new Buffer(b64Encoded, "base64").toString("binary")
    }
}

const regexEscape = string => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")

/**
 * @param {number} amount
 * @param {string} typeCode Transaction type code, C=expense, D=income, T=transfer
 * @return {string} Sign code: N=negative, P=positive, X=n/a
 */
const amountSign = (amount, txnTypeCode) => {
    if (txnTypeCode === "D" && amount > 0) return "P" //regular income txn
    else if (txnTypeCode === "D" && amount < 0) return "N" //reversal of income
    else if (txnTypeCode === "C" && amount > 0) return "N" //regular expense txn
    else if (txnTypeCode === "C" && amount < 0) return "P" //reversal of expense
    else if (txnTypeCode === "T" && amount > 0) return "P" //incoming transfer
    else if (txnTypeCode === "T" && amount < 0) return "N" //outgoing transfer
    else if (txnTypeCode === "B" && amount > 0) return "P" //positive balance
    else if (txnTypeCode === "B" && amount < 0) return "N" //negative balance
    else if (amount === 0) return "0"
    else return "X"
}

const iso2shortDate = d => {
    if (d) {
        let m = d.match(ISO_DATE_REGEX)
        let s = `${m[2]}/${m[3]}/${m[1]}`
        // console.log('ISO-2-SHORT-DATE >',d, s, m)
        if (m) return s
    }
    return ""
}

const verifyISODate = dt => {
    return ISO_DATE_REGEX.test(dt)
}

const parseISODate = d => {
    if (ISO_DATE_REGEX.test(d)) {
        let m = d.match(ISO_DATE_REGEX)
        return { year: m[1], month: m[2], day: m[3] }
    }
    return null
}

const iso2Date = (d, tz) => {
    let d2 = parseISODate(d)
    if (d2 && !tz) return new Date(d2.year, d2.month - 1, d2.day) // in JS month is 0-indexed
    if (d2 && tz.toLowerCase() === "utc")
        return new Date(Date.UTC(d2.year, d2.month - 1, d2.day))
    // in JS month is 0-indexed
    else return null
}

const date2pretty = (d, lang="EN") => {
    lang = lang.toUpperCase()
    if (lang === "EN") {
        return `${d.getDate()}-${MONTHS[d.getMonth()]}-${d.getFullYear()}`
    } else if (lang === "ES") {
        return `${d.getDate()}-${MONTHS_ES[d.getMonth()]}-${d.getFullYear()}`
    }
}

const date2iso = d => {
    let year = d.getFullYear()
    let month = (d.getMonth() + 1).toString().padStart(2,"0")
    let day = d.getDate().toString().padStart(2,"0")
    return `${year}-${month}-${day}`
}

const date2short= (d, lang="EN") => {
    lang = lang.toUpperCase()
    let year = d.getFullYear()
    let month = (d.getMonth() + 1).toString().padStart(2,"0")
    let day = d.getDate().toString().padStart(2,"0")
    if (lang === "EN") return `${month}/${day}/${year}`
    if (lang === "ES") return `${day}/${month}/${year}`
}

const iso2time = d => {
    let d2 = iso2Date(d)
    if (d2) return d2.getTime()
    else return null
}

const verifyShortDate = d => SHORT_DATE_REGEX.test(d)

const parseShortDate = d => {
    if (SHORT_DATE_REGEX.test(d)) {
        let m = d.match(SHORT_DATE_REGEX)
        if (m) return { year: m[3], month: m[1], day: m[2] } //`${valMatch[3]}-${valMatch[1]}-${valMatch[2]}`
    }
    return null
}

const shortDateToISO = d => {
    let t = parseShortDate(d)
    if (t) return `${t.year}-${t.month}-${t.day}`
    else return ""
}

const extractValue = e => {
    if (e === undefined || e === null) return e
    if (e.target) return e.target.value
    if (e.item_name) return e.item_name
    if (e.item_value) return e.item_value
    if (e.value) return e.value
    return e
}

const compareStrings = (a, b, order) =>
    ("" + order === "asc" ? nvl(a, "") : nvl(b, "")).localeCompare(
        order === "asc" ? b : a
    ) // force {a} to be a string to avoid exceptions

const compareNumbers = (a, b, order) => (order === "desc" ? b - a : a - b)

const compareDates = (a, b, order) => {
    let d1 = iso2time(a)
    let d2 = iso2time(b)
    if (order === "desc") return d2 - d1
    else return d1 - d2
}

const getUniqueValues = (arr, prop) => [
    ...new Set(
        arr
            .filter(e => (e === null || e === undefined ? false : true))
            .map(e => e[prop])
    ),
]

const numberToHex = n =>
    typeof n === "number" ? n.toString(16).padStart(2, "0") : n.toString()

const encodeValue = val => {
    if (typeof val === "number")
        return CJS.enc.Hex.parse(val.toString(16).padStart(2, "0"))
    else if (val === undefined || val === null || val === "") return undefined
    else return CJS.enc.Utf8.parse(val.toString())
}

const getUniqueProps = (arr, props = []) => {
    let arr2 = arr.map(e =>
        props.reduce((p, c) => {
            if (e[c]) p[c] = e[c]
            return p
        }, {})
    )
    // console.log(arr2)
    let arr3 = arr2.map(e => {
        let h = CJS.algo.SHA256.create()
        Object.entries(e).map(([k, v]) => {
            h.update(encodeValue(k))
            h.update(encodeValue(v))
        })
        return { ...e, hash: h.finalize().toString() }
    })
    // console.log(arr3)
    let arr4 = arr3.reduce(
        (p, c) => {
            let { hash, ...rest } = c
            if (!p.hashes.includes(hash))
                return { data: [...p.data, rest], hashes: [...p.hashes, hash] }
            else return p
        },
        { data: [], hashes: [] }
    )
    // console.log(arr4)
    return arr4.data
}

/* 
If you'd like to convert an uint8array to a NodeJS Buffer, use Buffer.from(arrayBuffer, offset, length) 
in order not to copy the underlying ArrayBuffer, while Buffer.from(uint8array) copies it
source: https://github.com/msgpack/msgpack-javascript#api
*/
const uint8tobuf = a => Buffer.from(a.buffer, a.byteOffset, a.byteLength)

const b64touint8 = base64 => {
    var binary_string = atob(base64)
    var len = binary_string.length
    var bytes = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
}

const sortObject = (o, includeEmpty = false) => {
    return Object.entries(o)
        .filter(
            (e => e[1] !== null && e[1] !== undefined && e[1] !== "") ||
                includeEmpty
        ) // filter entries with empty values
        .sort((a, b) => ("" + a[0]).localeCompare(b[0])) // sort by key alphabetically
        .reduce((p, c) => {
            p[c[0]] = c[1]
            return p
        }, {}) // reconstruct object with sorted keys
}

const pack = (obj, { includeEmpty = false, encoder = "b64" }) => {
    let enc = uint8tobuf(msgpack.encode(sortObject(obj, includeEmpty)))
    return encoder === "b64" || encoder === "base64"
        ? enc.toString("base64")
        : enc
}
const unpack = encb64 => msgpack.decode(b64touint8(encb64))

const hashUpdateValue = (val, hash = null) => {
    hash = hash || CJS.algo.SHA256.create()
    if (val === undefined || val === null || val === "") return hash
    else if (typeof val === "string" || typeof val === "number")
        hash.update(encodeValue(val))
    else if (Array.isArray(val)) {
        hash = CJS.algo.SHA256.create()
        val.map(e => hashUpdateValue(e, hash)) // loop
    } else if (typeof val === "object") {
        // Object.entries(val).map(([k,v]) => {
        //     if (v !== undefined && v !== null && val !== ''){
        //         hash.update(encodeValue(k))
        //         hash.update(encodeValue(v))
        //     }
        //     return null
        // })
        hash.update(pack(val, { encoder: "b64" }))
    }
    return hash
}

const sha256 = (obj, encoder = "hex") => {
    encoder = encoder.toLowerCase()
    if (encoder === "hex") encoder = CJS.enc.Hex
    else if (encoder === "b64") encoder = CJS.enc.Base64
    else if (encoder === "b64url") encoder = CJS.enc.Base64url
    else encoder = CJS.enc.Hex

    let hash = hashUpdateValue(obj)
    return hash.finalize().toString(encoder)
}

const round = (num, e = 2) =>
    Math.round((num + Number.EPSILON) * 10 ** e) / 10 ** e

const nvl = (a, b) => (a ? a : b)
const nvl2 = (a, b, c) => (a ? b : c)

const parseNumber = val => {
    let re = /[0-9\.-]/gi
    if (re.test(val)) return parseFloat(val.toString().match(re).join(""))
    else return 0.0
}

const formatNumber = (val, { decimals = 2, currency, asCurrency } = {}) => {
    let formatOptions = {
        // style: 'currency',
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: decimals, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: decimals, // (causes 2500.99 to be printed as $2,501
    }

    if (asCurrency) formatOptions.style = "currency"

    let formatter = null
    if (currency)
        formatter = new Intl.NumberFormat("en-US", {
            currency: currency,
            ...formatOptions,
        })
    else formatter = Intl.NumberFormat("en-US", formatOptions)
    return formatter.format(parseNumber(val))
}

async function digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8) // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("") // convert bytes to hex string
    return hashHex
}

const floor = (x, y = 1) => parseInt(x - (x % y))
const ceiling = (x, y = 1) => parseInt(x % y === 0 ? x : x + (y - (x % y)))
const range = n => {
    let a = []
    for (let i = 0; i < n; i++) a.push(i)
    return a
}

const objSha256 = (obj, encoder = "hex") => {
    console.log(obj, encoder)
    if (encoder === "hex") encoder = CJS.enc.Hex
    else if (encoder === "b64") encoder = CJS.enc.Base64
    else if (encoder === "b64url") encoder = CJS.enc.Base64url
    else encoder = CJS.enc.Hex

    let hash = hashUpdateValue(obj)
    return hash.finalize().toString(encoder)
}

export function xtob(hex) {
    if (hex.substr(0, 2) === "0x") hex = hex.substr(2)
    if (hex.length % 2 === 1) hex = "0" + hex
    for (var bytes = [], c = 0; c < hex.length; c += 2)
        bytes.push(parseInt(hex.substr(c, 2), 16))
    return bytes
}

const wtox = w => CJS.enc.Hex.stringify(w)
const wtob = w => xtob(wtox(w))

const pbkdf = (password, user = "anonymous") => {
    let salt = `a pich of salt for ${user}`
    let key = wtox(
        CJS.PBKDF2(password, salt, {
            keySize: 512 / 32,
            iterations: 2048,
            hasher: CJS.algo.SHA512,
        })
    )
    return key.substring(0, 64) // 32 bytes
}

const copyObject = (obj, { exclude = [], notContains = [] }) => {
    return Object.entries(obj).reduce((p, c, i, a) => {
        let contains = notContains.reduce(
            (p2, c2) => new RegExp(c2).test(c[0]) || p2,
            false
        ) // or use indexOf
        // console.log(c[0], exclude.includes(c[0]), contains)
        if (!exclude.includes(c[0]) && !contains) p[c[0]] = c[1]
        return p
    }, {})
}

const isNumbers = val => /^[0-9]{1,}$/.test(val)
const isNumeric = val => /^[0-9.-]{1,}$/.test(val)

const parseAmount = val => {
    let re = /[0-9\.-]/gi
    if (re.test(val)) return parseFloat(val.match(re).join(""))
    else return ""
}

function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

const Utils = {
    ISO_DATE_REGEX,
    SHORT_DATE_REGEX,
    MONTHS,
    MONTHS_ES,
    MONTHS_FULL,
    MONTHS_FULL_ES,
    CJS,
    // SHA256,
    sha256,
    amountSign,
    iso2shortDate,
    parseISODate,
    iso2Date,
    iso2time,
    extractValue,
    compareStrings,
    compareNumbers,
    compareDates,
    getUniqueValues,
    verifyISODate,
    round,
    verifyShortDate,
    parseShortDate,
    shortDateToISO,
    nvl,
    nvl2,
    parseNumber,
    formatNumber,
    getUniqueProps,
    floor,
    ceiling,
    range,
    date2pretty,
    date2iso,
    date2short,
    uint8tobuf,
    b64touint8,
    pack,
    unpack,
    regexEscape,
    pbkdf,
    copyObject,
    parseAmount,
    isNumeric,
    isNumbers,
    deleteAllCookies,
}

// module.exports = {...Utils}
export default Utils
