/** @format */

import React from "react"
import {
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Bar,
    Tooltip,
} from "recharts"

const DashVerticalBarChart = ({ title, data, dataKeyX, dataKeyY }) => {
    if (!data) return <></>
    return (
        <BarChart
            data={data}
            layout="vertical"
            height={100 + data.length * 25}
            width={900}
            margin={{ top: 25, right: 5, bottom: 5, left: 70 }}
        >
            <text
                x={900 / 2}
                y={10}
                fill="black"
                textAnchor="middle"
                dominantBaseline="central"
            >
                <tspan fontSize="18">{title ?? "Title"}</tspan>
            </text>
            <XAxis type="number" orientation="top" />
            <YAxis
                type="category"
                dataKey={dataKeyY}
                width={150}
                interval={0}
                tickFormatter={val =>
                    val.length > 16 ? `${val.substr(0, 16)}...` : val
                }
            />
            <Tooltip />
            <Bar dataKey={dataKeyX} fill="#8884d8" />
        </BarChart>
    )
}

export default DashVerticalBarChart
