/** @format */

import React from "react"
import TextDropdown from "./TextDropdown"
import DiagnosticItem from "./DiagnosticItem"

const PrimaryDiagnostic = ({ data, onSelection, onFocus, onClick, hide, disabled, triggerCheck, ...props }) => {
    if (hide) return <></>
    return (
        <TextDropdown 
            data={data}
            onSelection={onSelection}
            onFocus={onFocus}
            onClick={onClick}
            idColumn="diag_pri_cd"
            nameColumn="diag_pri_na"
            label="Diagnóstico Primario"
            disabled={disabled}
            sort={true}
            ItemDiv={DiagnosticItem}
            modalStyle={{ display: "static" }}
            maxHeight="60vh"
            modalClassName={"list-view"}
            wrap
            triggerCheck={triggerCheck}
            {...props}
        />
    )
}

export default PrimaryDiagnostic
