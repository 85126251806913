import React from 'react'

import Div from './Div'
import { AiOutlineClose } from 'react-icons/ai'

const LockedValue = ({ show, value, wrap, onClick }) => {
  return (
    <Div
        show={show}
        className="form-final"
        onClick={onClick}
        style={{ whiteSpace: (wrap ? "break-spaces" : "nowrap" ) }}
    >
        <div className="form-final-value">{value}</div>
        <div className="form-final-icon">
            <AiOutlineClose/>
        </div>
    </Div>
  )
}

export default LockedValue