/** @format */

import React, { useRef, useState } from "react"
import Utils from "../utils"
import Alert from "./Alert"
import { MdOutlineDashboard } from "react-icons/md"
import { FaWpforms } from "react-icons/fa"
import { IconContext } from "react-icons"

const users = {
    form: {
        email: "indicadores.sns@protonmail.com",
        check: "33d20f4d98f70e297e9b4d5690086524af96ab29e4fa0ce386e44a3315e7d211"
    },
    dash: {
        email: "indicadores.sns.admin@protonmail.com",
        check: "1dd689b8ca1083139dc1b35537baa6b30031d344e7d5f28be14a7800145bb998"
    },
}

const SignIn = ({ supabase, onSignIn }) => {
    const[ alert, setAlert ] = useState("")
    const[ context, setContext ] = useState("form")
    const passwordRef = useRef()

    const handleContext = async () => {
        setContext(c => {
            if (c === 'form') return 'dash'
            if (c === 'dash') return 'form'
        })
    }

    const handleSignIn = async () => {
        let email = users[context].email
        let password = passwordRef.current.value
        let key = Utils.pbkdf(password, email)

        // Early check to prevent invalid POST requests
        let checkHash = Utils.pbkdf(key, email + 'check')
        if (checkHash !== users[context].check) {
            setAlert("Contraseña inválida")
            return
        }
        
        let { data, error } = await supabase.auth.signInWithPassword({ email, password: key })

        if (error) {
            console.error(error)
            setAlert("Contraseña inválida")
            return
        }
        
        let { session, user } = data
        
        setAlert("")
        if(onSignIn) onSignIn(session)
    }

    return (
        <div className="p-4 d-flex flex-column col col-10 col-md-8 col-lg-6 m-auto border border primary rounded shadow bg-light">
            <div className="flex-row justify-content-between">
                <div className="p-2">{'Contraseña' + (context === 'dash' ? ' de Administrador' : '')}</div>
                <div className="hover" onClick={handleContext}>
                    <IconContext.Provider value={{ className: "shared-class", size: 30 }}>
                        { context==='form' && <MdOutlineDashboard/> }
                        { context==='dash' && <FaWpforms/> }
                    </IconContext.Provider>
                </div>
            </div>
            <div className="p-2">
                <input
                    ref={passwordRef}
                    type="password"
                    placeholder="Ingrese contraseña"
                    className="form-input"
                    onKeyUp={e => e.key === 'Enter' ? handleSignIn() : null}
                />
            </div>
            { alert && <Alert variant="danger" alert={alert} /> }
            <div className="p-2 d-flex justify-content-end">
                <div 
                    className="btn btn-primary"
                    onClick={handleSignIn}
                >
                    Ingresar
                </div>
            </div>
        </div>
    )
}

export default SignIn
