/** @format */

import React, { useState, useEffect } from "react"
import NumberInput from "./NumberInput"
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup"
import ToggleButton from "react-bootstrap/ToggleButton"
import FormGroup from "./FormGroup"

const PatientAge = ({ onChange, initValue, triggerCheck, ...props }) => {
    const [age, setAge] = useState({})
    const [period, setPeriod] = useState(12)
    const [minValue, setMinValue] = useState(1)
    const [maxValue, setMaxValue] = useState(120)
    // const [maxValue, setMaxValue] = useState(120)
    const [triggerCheck2, setTriggerCheck2] = useState(0)

    const options = [
        { name: "años", value: 12 },
        { name: "meses", value: 1 },
    ]

    const handlePeriodChange = val => {
        setPeriod(val)
        if (val === 12) {
            setMinValue(1)
            setMaxValue(120)
        } else if (val === 1) {
            setMinValue(0)
            setMaxValue(11)
        }
        setTriggerCheck2(t => ++t)
    }

    const handleOnChange = item => {
        if (onChange) onChange({...item, period})
    }

    return (
        <FormGroup>
            <div className="flex-grow-1">
                <NumberInput
                    label="edad"
                    // placeholder="edad"
                    onChange={handleOnChange}
                    minValue={minValue}
                    maxValue={maxValue}
                    initValue={initValue}
                    triggerCheck={triggerCheck + triggerCheck2 * 1000}
                    noLabel
                    // noFeedback
                    {...props}
                />
            </div>
            <div className="p-2">
                <ToggleButtonGroup 
                    type="radio" 
                    name="age-period"
                    defaultValue={period}
                    onChange={handlePeriodChange}
                >
                    {options.map((e, i) => (
                        <ToggleButton
                            key={`age_opt_${i}`}
                            id={`age_opt_${i}`}
                            variant="outline-primary"
                            value={e.value}
                        >
                            {e.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
        </FormGroup>
    )
}

export default PatientAge
