/** @format */

import React from "react"
import TextDropdown from "./TextDropdown"
import DiagnosticItem from "./DiagnosticItem"

const SecondaryDiagnostic = ({ data, onSelection, onFocus, onClick, hide, disabled, triggerCheck, ...props }) => {
    if (hide) return <></>
    return (
        <TextDropdown 
            data={data}
            onSelection={onSelection}
            onFocus={onFocus}
            onClick={onClick}
            idColumn="diag_sec_cd"
            nameColumn="diag_sec_na"
            label="Diagnóstico Secundario"
            disabled={disabled}
            sort={true}
            ItemDiv={DiagnosticItem}
            modalClassName="list-view"
            maxHeight="70vh"
            wrap
            triggerCheck={triggerCheck}
            {...props}
        />
    )
}

export default SecondaryDiagnostic
