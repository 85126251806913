/** @format */

import React, { useEffect, useState } from "react"
import FormGroup from "./FormGroup"
import Input from "./Input"
import TextDropdown from "./TextDropdown"
import TextInput from "./TextInput"

const Reasons = ({ data, onSelection, disabled, triggerCheck, ...props }) => {
    const [reason, setReason] = useState({})
    const [showOther, setShowOther] = useState(false)

    const label = "Motivo de Consulta"

    onSelection = onSelection || (() => {})

    const handleSelection = item => {
        if (item && item.rsn_id >= 98) {
            setShowOther(true)
            item.isValid = false
        } else {
            setReason(i => ({...i, other: undefined}))
            setShowOther(false)
        }
        setReason(item)
    }

    const handleOtherChange = item => {
        if (!item || !item.isValid) {
            if (reason.rsn_id >= 98) setReason(i => ({...i, other: item.value, isValid: false}))
        }
        else setReason(currentInsurer => {
            let newReason = { ...currentInsurer }
            newReason.isValid = true
            newReason.other = item.value
            return newReason
        })
    }

    useEffect(() => {
        onSelection(reason)
    }, [reason])

    return (
        <FormGroup type="column" label={label} labelClassName="px-2 pt-3" required >
            <TextDropdown
                data={[...data].sort((a,b) => a - b)}
                label={label}
                onSelection={handleSelection}
                nameColumn="rsn_na"
                disabled={disabled}
                sort={false}
                triggerCheck={triggerCheck}
                noLabel
                {...props}
            />
            <TextInput
                hide={!showOther}
                placeholder="Especifique..."
                onChange={handleOtherChange}
                minLength={3}
                triggerCheck={triggerCheck}
                noLabel
            />
        </FormGroup>
    )
}

export default Reasons
