import React, { useState, useRef, useEffect, useCallback, forwardRef } from 'react'
import Colors from '../colors'
import { AiFillCheckCircle } from 'react-icons/ai'

const Cell = forwardRef(({ show, type, row, col, obj, useIntelli, onFocusOut, onBlur, children, floatingLabel, verifyValue, valid, ...props }, ref) => {
  
  if (!type) type = 'text'
  if (show === undefined) show = true
  if (valid === undefined && verifyValue) valid = verifyValue(props.value)
  
  const onCellBlur = e => {
      let cell = e.target
      let R = cell.getAttribute('data-row')
      let C = cell.getAttribute('data-col')
      let obj = cell.getAttribute('data-obj')
      // console.log(R,C)
      if (onFocusOut) onFocusOut(cell, R, C, obj)
      else if (onBlur) onBlur(cell, R, C, obj)
    }

  if(!show) return <></>
  return (
      <div style={{ padding: "0", margin: "0", position: "relative" }}>
      <input 
        { ...props }
        ref={ref}
        type={type}
        className={`gr-cell ${props.className}`} 
        onBlur={onCellBlur}
        data-row={row}
        data-col={col}
        data-obj={obj}
        />

          { children }

          {
            ((floatingLabel || valid) && props.value) &&
            <div 
              className="rounded rounded-1" 
              style={{ 
                position: "absolute", 
                top: "0.3rem", 
                right: "0.3rem", 
                fontSize: "0.6rem",
                color: Colors.mkFg0,
                backgroundColor: Colors.mkBg0,
                padding: "0.1rem 0.3rem 0.1rem 0.3rem",
                zIndex: 99,
                }}>
                <span>{ floatingLabel }</span>
                { 
                  valid !== undefined && 
                  <span style={{ marginLeft: "0.2rem", fontSize: "0.8rem", color: valid ? Colors.green : Colors.red }}>
                    <AiFillCheckCircle />
                  </span>
                }
            </div>
          }
      </div>
  )
})

export default Cell