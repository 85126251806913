/** @format */

import React from "react"
import TextDropdown from "./TextDropdown"

const Hospitals2 = ({ hide, data, onSelection, ...props }) => {
    if (hide) return <></>
    return (
        <TextDropdown 
            data={data}
            onSelection={onSelection}
            idColumn="hosp_id"
            nameColumn="hosp_na"
            label="Hospital"
            {...props}
        />
    )
}

export default Hospitals2
