/** @format */

import React, { useState, useEffect } from "react"
import Utils from "../utils"
import PatientRow from "./PatientRow"
import Input from "./Input"
import { FiSearch } from "react-icons/fi"

const PatientsList = ({ supabase, hospitalId, formData, onUpdate }) => {
    const [patients, setPatients] = useState([])
    const [filteredPatients, setFilteredPatients] = useState([])
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(true)

    const filter = (data, text) => data.filter(x => (new RegExp(text, 'i')).test(x.p_name))

    const fetchPatients = async() => {
        let { data, status, statusText, error } = await supabase
            .rpc('patient_log_full', {
                p_del_ind: false,
                p_exp_ind: false,
                p_disc_ind: false,
                p_hosp_id: hospitalId
            })
        // console.log(data)
        // console.error(error)
        if (error) throw error
        setPatients(data)
        setFilteredPatients(filter(data, searchText))
        return data
    }

    const handleChange = e => {
        let text = e.target.value
        setSearchText(text)
        setFilteredPatients(filter(patients, text))
    }

    const handleSave = () => {
        fetchPatients()
        if (onUpdate) onUpdate()
    }

    const dateClassName = "col m-0 px-0 py-1 col-4 col-md-3 d-flex justify-content-center"
    const patientClassName = "col m-0 px-2 py-1 col-8 col-md-9 overflow-hidden"

    useEffect(() => {
        fetchPatients()
        .then(() => setLoading(false))
        .catch(err => {
            console.error(err)
            setLoading(true)
        })
        return () => setLoading(false)
    },[])

    if (loading) return (
        <div>Cargando...</div>
    )
    return <div>
        <div className="p-2" style={{position: "relative"}}>
            <input
                type="text"
                value={searchText}
                onChange={handleChange}
                placeholder="Busca paciente por nombre"
                className="form-input"
                />
            <div className="p-2 pe-3 form-floating-icon text-secondary"><FiSearch /></div>
        </div>
        <div className="px-2 pt-2 d-flex flex-column">
            <div className="row m-0 p-0 mb-2 bg-primary text-light rounded">
                <div 
                    className={dateClassName} 
                >
                    Fecha
                </div>
                <div className={`${patientClassName} d-flex justify-content-center`}>
                    Paciente
                </div>
            </div>
            {
                filteredPatients.map((e, i) => {
                    return (
                        <PatientRow
                            key={e.pid}
                            patient={e}
                            idx={i}
                            dateClassName={dateClassName}
                            patientClassName={patientClassName}
                            formData={formData}
                            supabase={supabase}
                            onSave={handleSave}
                        />
                    )
                })
            }
        </div>
    </div>
}

export default PatientsList
