import React, { useState } from 'react'
import Utils from '../utils'
import PatientModal from './PatientModal'

const PatientRow = ({ patient, idx, dateClassName, patientClassName, formData, supabase, onSave }) => {

    const [showModal, setShowModal] = useState(false)

    let background = idx % 2 === 0 ? 'bg-dark bg-opacity-10' : 'bg-light'
    let age = patient.p_age_yr > 0 ? `${patient.p_age_yr} años` : `${patient.p_age_mth} meses`

    function handleSave() {
        setShowModal(false)
        if (onSave) onSave()
    }

    return (
        <>
            <div 
                className={`row m-0 p-0 ${background} text-dark hover primary`}
                onClick={() => setShowModal(true)}
                key={patient.pid}
            >
                <div 
                    className={dateClassName}
                >
                    {Utils.date2pretty(new Date(patient.arrival_ts))}
                </div>
                <div className={patientClassName}>
                    {`${patient.p_name} (${age})`}
                </div>
                
            </div>
            <PatientModal
                patient={patient}
                show={showModal}
                onDismiss={() => setShowModal(false)}
                onSave={handleSave}
                formData={formData}
                supabase={supabase}
            />
        </>
    )
}

export default PatientRow