/** @format */

import React, { useState, useEffect } from "react"

const ToggleSwitch = ({ show, label, onChange, checked, ...props }) => {
    const [isChecked, setIsChecked] = useState(checked ? true : false)

    const handleChange = () => {
        setIsChecked(c => !c)
    }

    useEffect(() => {
        if (onChange) onChange(isChecked)
    }, [isChecked])

    useEffect(() => {
        if (checked !== undefined) setIsChecked(checked)
    }, [checked])

    if (!show) return <></>
    return (
        <div className="px-2 pt-3 pb-1 d-flex flex-row">
            <div className="pe-3">{label}</div>
            <div className="form-check form-switch hover">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleChange}
                />
            </div>
        </div>
    )
}

export default ToggleSwitch
