/** @format */

import React from "react"
import TextDropdown from "./TextDropdown"

const Discharge = ({ data, onSelection, disabled, triggerCheck, hide, ...props }) => {
    if (hide) return <></>
    return (
        <TextDropdown 
            data={data}
            onSelection={onSelection}
            idColumn="disc_id"
            nameColumn="disc_na"
            label="Razón de Egreso"
            disabled={disabled}
            sort={true}
            triggerCheck={triggerCheck}
            required
            {...props}
        />
    )
}

export default Discharge
