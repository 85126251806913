import React from 'react'
import Row from 'react-bootstrap/Row'

const SearchItem = ({ children, onClick, item, active }) => {
  let itemClass = active ? 'item-hover active' : 'item-hover'
  return (
    <Row className={`px-2 py-1 m-0 d-flex flex-column justify-content-center ${itemClass}`}
        onClick={() => onClick(item)}
        >
            { children }
    </Row>
  )
}

export default SearchItem