/** @format */

import React, { useState, useMemo, useEffect } from "react"
import TimeInput from "./TimeInput"
import TextDropdown from "./TextDropdown"
import Utils from "../utils"
import FormGroup from "./FormGroup"
import { type } from "@testing-library/user-event/dist/type"

const addDays = (d, n) => new Date(d.getFullYear(), d.getMonth(), d.getDate() + n)

const getDates = (date, count, offset) => {
    count = count || 10
    offset = offset || 0
    date = date || new Date()
    let days = Utils.range(count).map((e,i) => addDays(date, i * -1 + offset), "ES")
    let dates = days.map((e, i) => {
        return {
            date: e,
            short: Utils.date2short(e, "ES"),
            pretty: Utils.date2pretty(e, "ES"),
            iso: Utils.date2iso(e, "ES"),
            ts: Utils.floor(e.getTime() / 1000),
        }
    })
    return dates
}

const isDate = d => typeof d.getMonth === 'function'
const sign = n => n > 0 ? '+' : (n < 0 ? '-' : '')

const dateDifference = (a, b) => {
    if (!isDate(a) || !isDate(b)) return
    let x = Utils.floor(a.getTime() / 1000)
    let y = Utils.floor(b.getTime() / 1000)
    let tsDelta = y - x
    let days = Utils.floor(tsDelta / (24 * 60 * 60))
    let hours = Utils.floor((tsDelta % (24 * 60 * 60)) / (60 * 60))
    let minutes = Utils.floor((tsDelta % (60 * 60)) / 60)
    let seconds = Utils.floor(tsDelta % 60)
    let msg = [[days,"día(s)"],[hours,"hora(s)"],[minutes,"minuto(s)"],].reduce((p,c) => {
        if (c[0] !== 0) return p + ` ${sign(c[0])}` + c.join(' ')
        else return p
    }, '')
    return { days, hours, minutes, seconds, tsDelta, msg }
}

const Datetime = ({ label, name, count, offset, date, onSelection, disabled, depLabel, minTimeDifference, required, triggerCheck, hide, ...props }) => {
    onSelection = onSelection || (() => {})
    const [ item, setItem ] = useState({})
    const [ time, setTime ] = useState({})
    const [ alert, setAlert ] = useState(null)
    const [ invalid, setInvalid ] = useState(null)
    // const [ triggerCheck, setTriggerCheck ] = useState(0)
    const [ defaultValue, setDefaultValue ] = useState('')
    const [ isFinal, setIsFinal ] = useState(false)

    const dates = useMemo(() => getDates(date, count, offset), [date, count, offset])

    const timeUnits = {
        m: { label: 'minutos', seconds: 60 },
        h: { label: 'horas', seconds: 60 * 60 },
    }

    const validateItem = (currentItem, currentDate) => {
        if (currentItem && currentItem.date && currentDate) {
            let diff = dateDifference(currentDate, currentItem.date)
            let minTime = parseInt(minTimeDifference)
            let unit = minTimeDifference ? minTimeDifference.substr(-1) : 'm'
            let t = timeUnits[unit]
            // console.log(diff)
            if (diff && diff.tsDelta > minTime * t.seconds) {
                setAlert(<>{`Tiempo es ${diff.msg} después de `}<strong>{depLabel}</strong>{`. Asegúrese que es correcto.`}</>)
                setInvalid(null)
                currentItem.isValid = true
            } else if (diff && diff.tsDelta < 0) {
                setAlert(null)
                setInvalid(<>{`Tiempo es ${diff.msg} anterior a `}<strong>{depLabel}</strong>{`. Favor corregir.`}</>)
                currentItem.isValid = false
            } else if (currentItem.time && !currentItem.time.isValid) {
                currentItem.isValid = false
            } else {
                setAlert(null)
                setInvalid(null)
                currentItem.isValid = true
            }
        } else {
            setAlert(null)
            setInvalid(null)
        }
        return currentItem
    }

    const updateItem = (item, time) => {
        if (!item) return {}
        let newItem = { ...item, time }
        // newItem.isValid = (item.isValid ?? false) && (time.isValid ?? false)
        if (time && newItem.date && newItem.date.setHours) {
            let { hours, minutes } = time
            if (hours >= 0 && minutes >= 0) {
                newItem.date.setHours(hours, minutes)
                newItem.date = new Date(newItem.date.getTime())
            }
        }
        return validateItem(newItem, date)
    }

    const handleDateSelection = newDate => {
        // console.log(selectedItem)
        setItem(curr => updateItem(newDate, curr.time))
        // if (onSelection) onSelection({...selectedItem, time})
    }
    
    const handleTimeChange = newTime => {
        setTime(newTime)
        setItem(curr => updateItem(curr, newTime))
        // if (onSelection) onSelection({...item, time: selectedItem})
    }

    useEffect(() => {
        if (date) {
            offset = offset || 0
            let newDate = dates[offset]
            let item = { isValid: true, ...newDate }
            setItem(curr => updateItem(item, curr.time))
            setDefaultValue(newDate.pretty)
            setIsFinal(true)
        } else {
            setItem(curr => ({ time: curr.time }))
            setDefaultValue('')
            setAlert(null)
            setInvalid(null)
            setIsFinal(false)
        }
    },[date, offset])

    useEffect(() => {
        if (item === undefined) onSelection({})
        onSelection(item)
    },[item])

    if (hide) return <></>
    return (
        <FormGroup 
            label={label} 
            labelClassName={"px-2 pt-3"} 
            alert={alert}
            invalid={invalid}
            required={required}
        >
            <TextDropdown
                className="flex-grow-1"
                label="día"
                placeholder="día"
                data={dates}
                nameColumn="pretty"
                onSelection={handleDateSelection}
                xs="8"
                disabled={disabled}
                final={isFinal}
                defaultValue={defaultValue}
                triggerCheck={triggerCheck}
                noLabel
                {...props}
            />
            <TimeInput
                label="hora"
                name={(name||label).replaceAll(' ','-').toLowerCase()}
                placeholder="00:00"
                // initValue="00:00"
                xs="4"
                noLabel
                onChange={handleTimeChange}
                triggerCheck={triggerCheck}
            />
        </FormGroup>
    )
}

export default Datetime
